import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const Testimonials = ({data}) => {
  return (
    <div className={`testimonials`}>
      <div className='testimonials__container container'>
        <div className="testimonials__content">
          {data.label && (
            <h5 className='testimonials__label'>{data.label}</h5>
          )}
          
          {data.header && (
            <h2 className='testimonials__header'>{data.header}</h2>
          )}
          
          {data.copy && (
            <p className='testimonials__copy'>{data.copy}</p>
          )}
        </div>
        
          
          {data.testimonials.length > 0 && (
            <div className='testimonials__item-container'>
            {data.testimonials.map((testimonial, i)=> (
              <div className='testimonials__item' key={`item-${testimonial.id}`}>
              
              {testimonial.image && (
                <div className={`testimonials__image-container`}>
                  <Image media={testimonial.image} className="testimonials__image" />
                </div>
              )}
              {testimonial.label && (
                <h5 className='testimonials__item-label'>{testimonial.label}</h5>
              )}
              {testimonial.name && (
                <h4 className='testimonials__item-header'>{testimonial.name}</h4>
              )}
              
              {testimonial.header && (
                <h3 className='testimonials__item-header'>{testimonial.header}</h3>
              )}
              
              {testimonial.testimonial && (
                <p className='testimonials__item-copy'>{testimonial.testimonial}</p>
              )}
              </div>
            ))}
            </div>
          )}
      </div>
    </div>
  )
}
export default Testimonials

