import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const Team = ({data}) => {
  const createMarkup = (markup) => {
    return {__html: markup};
  }
  
  return (
    <div className={`team`}>
      <div className='team__container container'>
        <div className="team__content">
          {data.label && (
            <h5 className='team__label'>{data.label}</h5>
          )}
          
          {data.header && (
            <h2 className='team__header'>{data.header}</h2>
          )}
          
          {data.copy && (
            <p className='team__copy'>{data.copy}</p>
          )}
        </div>
        
          
          {data.members.length > 0 && (
            <div className='team__member-container'>
            {data.members.map((member, i)=> (
              <div className='team__member' key={`column-${member.id}`}>
              {member.image && (
                <div className={`team__image-container`}>
                  <Image media={member.image} className="team__image" />
                </div>
              )}
              {member.position && (
                <h5 className='team__member-label'>{member.position}</h5>
              )}
              
              {member.name && (
                <h3 className='team__member-header'>{member.name}</h3>
              )}
              
              {member.bio && (
                <div className='team__member-copy' dangerouslySetInnerHTML={createMarkup(member.bio)}/>
              )}
              </div>
            ))}
            <div className='team__member'></div>
            </div>
          )}
      </div>
    </div>
  )
}
export default Team

