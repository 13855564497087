import React, { useEffect } from "react"
import HeroLarge from "@/components/sections/hero-large"
import HeroMedium from "@/components/sections/hero-medium"
import HeroSmall from "@/components/sections/hero-small"
import Alternator from "@/components/sections/alternator"
import ColumnsTwo from "@/components/sections/columns-two"
import ColumnsThree from "@/components/sections/columns-three"
import ColumnsFour from "@/components/sections/columns-four"
import CallToAction from "@/components/sections/call-to-action"
import Testimonials from "@/components/sections/testimonials"
import Team from "@/components/sections/team"
import Content from "@/components/sections/content"
import Accordion from "@/components/sections/accordion"
import Form from "@/components/sections/form"
import Logos from "@/components/sections/logos"

// Map Strapi sections to section components
const sectionComponents = {
  "sections.hero-large": HeroLarge,
  "sections.hero-medium": HeroMedium,
  "sections.hero-small": HeroSmall,
  "sections.alternator": Alternator,
  "sections.columns-two": ColumnsTwo,
  "sections.columns-three": ColumnsThree,
  "sections.columns-four": ColumnsFour,
  "sections.call-to-action": CallToAction,
  "sections.testimonials": Testimonials,
  "sections.team": Team,
  "sections.content": Content,
  "sections.accordion": Accordion,
  "sections.form": Form,
  "sections.logos": Logos,
}

// Display a section individually
const Section = ({ section }) => {
  let SectionComponent = sectionComponents[section.strapi_component];

  if (!SectionComponent) {
    return null
  }

  return <SectionComponent data={section} />
}

const Sections = ({ sections }) => {
  
  return (
    <div>
      {sections.map((section, i) => (
        <Section
          section={section}
          key={`${section.__component}${(section.id, i)}`}
        />
      ))}
    </div>
  )
}

export default Sections
