import React from "react"
import CustomLink from '../custom-link'
import { StaticQuery, graphql } from "gatsby"
import Image from "../image";
import moment from "moment";


const Content = ({data}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          strapi {
            contentTypes {
              type
              slug
              id
            }
          }
        }
      `}
      render={ types => {
        const sortedNews = data.contents.sort(function(a,b){
          return new Date(b.date) - new Date(a.date);
        });



        const getType = (id, key) => {
          return types.strapi.contentTypes.find(x => x.id == id)[key]
        }
        return (
          <div className={`content`}>
            <div className='content__container container'>
              <div className="content__content">
                {data.label && (
                  <h5 className='content__label'>{data.label}</h5>
                )}

                {data.header && (
                  <h3 className='content__header'>{data.header}</h3>
                )}

                {data.copy && (
                  <p className='content__copy'>{data.copy}</p>
                )}
              </div>


                {data.contents.length > 0 && (
                  <div className='content__item-list'>
                  {sortedNews.map((content, i)=> (
                    <div className='content__item' key={`item-${content.id}`}>

                    {content.image && (
                      <div className={`content__image-container`}>
                        <Image media={content.image} className="content__image" />
                      </div>
                    )}

                    {!content.author.includes('/') && (
                      <CustomLink className='content__item-link h5' link={{url: `/${getType(content.content_type, 'slug')}/${content.slug}`, newTab: false}}>
                      {content.title}
                      </CustomLink>
                    )}

                    {content.author.includes('/') && (
                      <CustomLink className='content__item-link h5' link={{url: `${content.author}`, newTab: true}}>
                      {content.title}
                      </CustomLink>
                    )}


                    {content.description && (
                      <p className='content__item-description'>{content.description}</p>
                    )}

                    {content.published_at && (
                      <p className='content__item-date'>{moment(content.date).format("MMMM D, YYYY")}</p>
                    )}
                    </div>
                  ))}
                  <div className='content__item'/>

                  </div>
                )}
            </div>
          </div>
        )
      }
      }/>
  )
}
export default Content
