import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const ColumnsFour = ({data}) => {
  return (
    <div className={`columns-4`}>
      <div className='columns-4__container container'>
        
        <div className="columns-4__content">
          {data.label && (
            <h5 className='columns-4__label'>{data.label}</h5>
          )}
          
          {data.header && (
            <h2 className='columns-4__header'>{data.header}</h2>
          )}
          
          {data.copy && (
            <p className='columns-4__copy'>{data.copy}</p>
          )}
        </div>
        
        {data.columns.length > 0 && (
          <div className='columns-4__column-container'>
          {data.columns.map((column, i)=> (
            <div className='columns-4__column' key={`column-${column.id}`}>
            {column.image && (
              <div className={`columns-4__image-container`}>
                <Image media={column.image} className="columns-4__image" />
              </div>
            )}
            
            {column.label && (
              <h5 className='columns-4__column-label'>{column.label}</h5>
            )}
            
            {column.header && (
              <h3 className='columns-4__column-header'>{column.header}</h3>
            )}
            
            {column.copy && (
              <p className='columns-4__column-copy'>{column.copy}</p>
            )}
            </div>
          ))}
          </div>
        )}
        
        {data.cta && (
            <CustomLink className={`button-${data.cta.type} columns-4__cta`} key={data.cta.id} link={data.cta}>{data.cta.text}</CustomLink>
        )}
      </div>
    </div>
  )
}
export default ColumnsFour

