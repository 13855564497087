import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const CallToAction = ({data}) => {
  return (
    <div className={`call-to-action call-to-action--bg-${data.background}`}>
      <div className='call-to-action__container container'>
        <div className="call-to-action__content">

          {data.header && (
            <h1 className='call-to-action__header'>{data.header}</h1>
          )}

          {data.cta && (
              <CustomLink className={`button-${data.cta.type} call-to-action__cta`} key={data.cta.id} link={data.cta}>{data.cta.text}</CustomLink>
          )}
        </div>

      </div>

      {data.image && (
        <div className="call-to-action__image-container">
        <div className="call-to-action__image-overlay"/>
        <Image media={data.image} className="call-to-action__image" />
        </div>
      )}
    </div>
  )
}
export default CallToAction
