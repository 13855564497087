import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const Alternator = ({data}) => {
  return (
    <div className={`alternator alternator--layout-${data.layout}`}>
      <div className='alternator__container container'>
        <div className="alternator__content">
          {data.label && (
            <h5 className='alternator__label'>{data.label}</h5>
          )}

          {data.header && (
            <h2 className='alternator__header'>{data.header}</h2>
          )}

          {data.copy && (
            <div className='alternator__copy' dangerouslySetInnerHTML={{__html: data.copy}}/>
          )}

          {data.cta && (
              <CustomLink className={`button-${data.cta.type} alternator__cta`} key={data.cta.id} link={data.cta}>{data.cta.text}</CustomLink>
          )}
        </div>
<iframe style={{'display': 'none'}}width="500" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="Final Approved House Plan Interactive Map" src="//www.arcgis.com/apps/Embed/index.html?webmap=43ac3824d3674f39ad3cbd62502d404a&extent=-105.2754,39.5435,-104.5173,39.9058&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light"></iframe>
        {data.image && (
          <div className="alternator__image-container">
            <Image media={data.image} className="alternator__image" />
          </div>
        )}
      </div>
    </div>
  )

}
export default Alternator
