import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const Logos = ({data}) => {
  console.log(data);
  return (
    <div className={`logos`}>
      <div className='logos__container container'>


          {data.items.length > 0 && (
            <div className='logos__list'>
            {data.items.map((item, i)=> (
              <CustomLink className='logos__item' link={{url: `${item.link ? item.link : item.logo.url}`, newTab: true}}>
                <div className='logos__image-container' key={`column-${item.id}`}>
                  {(item.logo.ext === '.mp4') || item.link ? (
                    <>
                    <span className='logos__image-label'>Video</span>
                    <img className='logos__image vid' src={item.logo.previewUrl ? item.logo.previewUrl : item.logo.url}/>
                    </>

                  ):(
                    <Image className='logos__image' media={item.logo}/>

                  )}
                </div>
              </CustomLink>
            ))}
            </div>
          )}
      </div>
    </div>
  )
}
export default Logos
