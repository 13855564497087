import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const ColumnsTwo = ({data}) => {
  return (
    <div className={`columns-2`}>
      <div className='columns-2__container container'>

        <div className="columns-2__content">
          {data.label && (
            <h5 className='columns-2__label'>{data.label}</h5>
          )}
          
          {data.header && (
            <h2 className='columns-2__header'>{data.header}</h2>
          )}
          
          {data.copy && (
            <p className='columns-2__copy'>{data.copy}</p>
          )}
        </div>
        
        {data.columns.length > 0 && (
          <div className='columns-2__column-container'>
          {data.columns.map((column, i)=> (
            <div className='columns-2__column' key={`column-${column.id}`}>
            {column.image && (
              <div className={`columns-2__image-container columns-2__image-container--${column.imageSize}`}>
                <Image media={column.image} className="columns-2__image" />
              </div>
            )}
            {column.label && (
              <h5 className='columns-2__column-label'>{column.label}</h5>
            )}
            
            {column.header && (
              <h3 className='columns-2__column-header'>{column.header}</h3>
            )}
            
            {column.copy && (
              <p className='columns-2__column-copy'>{column.copy}</p>
            )}
            </div>
          ))}
          </div>
        )}
          
        {data.cta && (
            <CustomLink className={`button-${data.cta.type} columns-2__cta`} key={data.cta.id} link={data.cta}>{data.cta.text}</CustomLink>
        )}
      </div>
    </div>
  )
}
export default ColumnsTwo

