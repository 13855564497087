import React from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const HeroMedium = ({data}) => {
  return (
    <div className={`hero-medium`}>
      <div className='hero-medium__container container'>
        <div className='hero-medium__content'>
          {data.label && (
            <h5 className='hero-medium__label'>{data.label}</h5>
          )}

          {data.header && (
            <h1 className='hero-medium__header'>{data.header}</h1>
          )}

          {data.copy && (
            <p className='hero-medium__copy'>{data.copy}</p>
          )}

          {data.cta && (
              <CustomLink className={`button-${data.cta.type} hero-medium__cta`} key={data.cta.id} link={data.cta}>{data.cta.text}</CustomLink>
          )}
        </div>

        {data.image && (
          <div className="hero-medium__image-container">
          <div className="hero-medium__image-overlay"/>
            <Image media={data.image} className="hero-medium__image"/>
          </div>
        )}
      </div>
    </div>
  )
}
export default HeroMedium
