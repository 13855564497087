import React, { useState }  from "react"
import CustomLink from '../custom-link'
import Image from "../image";
import { StaticQuery, graphql } from "gatsby"
import axios from "axios";

const Form = ({data}) => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      form.reset()
    }
  }

  const submit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: data.form.endpoint,
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks! We will be in touch soon.", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      })
  }

  return (
    <StaticQuery
      query={graphql`
        {
          strapi {
            forms {
              id
              fields {
                id
                label
                name
                type
                value
              }
            }
          }
        }
      `}
      render={ forms => {

        const getFormFields = (id) => {

          const form = forms.strapi.forms.find(form => form.id == id )
          return form.fields.map((field)=>{
            const options = field.value ? field.value.split(',') : [];
            switch (field.type) {
              case "text":
                return (
                  <div className='form__form-field--half'>
                  <label className="input-text-label">{field.label}</label>
                  <input className="input-text" type="text" name={field.name} placeholder={field.value}/>
                  </div>
                );

              case "email":
                return (
                  <div className='form__form-field--half'>
                  <label className="input-text-label">{field.label}</label>
                  <input className="input-text" type="email" name={field.name} placeholder={field.value}/>
                  </div>
                );

              case "textarea":
                return (
                  <div className='form__form-field--full'>
                  <label className="input-text-label">{field.label}</label>
                  <textarea className="input-textarea" name={field.name} placeholder={field.value}></textarea>
                  </div>
                );

              case "radio":
                return (
                  <div className='form__form-field--full form__radio'>
                  <label className="input-text-label">{field.label}</label>

                    { options.map((option, i) => (
                      <label className="input-text-label radio">
                        <input type="radio" name={field.name} className="radio__input" value={option} />
                        <div className="radio__control"></div>
                        <div className="radio__label">{option}</div>
                      </label>
                      )
                    )}
                    </div>
                );

              case "checkbox":
                return (
                  <div className='form__form-field--full form__checkbox'>
                    <label className="input-text-label">{field.label}</label>

                    { options.map((option, i) => (
                      <label className="input-text-label checkbox">
                        <input type="checkbox" name={option} value={true}  className="checkbox__input" />
                        <div className="checkbox__control"></div>
                        <div className="checkbox__label">{option}</div>
                      </label>
                    )
                    )}
                </div>
                );

              case "select":
                return (
                  <div className='form__form-field--half'>
                  <label className="input-text-label">{field.label}</label>
                  <select className="input-select" placeholder={field.label} name={field.name}>
                  { options.map((option, i) => (
                    <option value={option}>{option}</option>
                  ))}
                  </select>
                  </div>
                );

              case "submit":
                return (
                  <div className='form__form-field--full form__submit'>

                  <button className="button-primary" type="submit">{field.label}</button>
                  </div>

                );

              default:
                return null;

            }
          })
        }
        return(
          <div className={`form`}>
            <div className='container'>
            <div className="form__content">
              {data.label && (
                <h5 className='form__label'>{data.label}</h5>
              )}

              {data.header && (
                <h2 className='form__header'>{data.header}</h2>
              )}

              {data.copy && (
                <p className='form__copy'>{data.copy}</p>
              )}
            </div>

            <form className="form__form" onSubmit={submit}>
              {getFormFields(data.form.id)}
              {serverState.submitting && (
                <p className='form__submitting'>Submitting...</p>
              )}
              {serverState.status && (
                <p className='form__message'>{serverState.status.msg}</p>
              )}
            </form>
            </div>
          </div>
        )
      }}/>
    )
}
export default Form
