import React, { useState }  from "react"
import CustomLink from '../custom-link'
import Image from "../image";

const Accordion = ({data}) => {
  const [accordion, setAccordion] = useState([]);
  
  const accordionAction = (id) => {
    if (accordion.includes(id)) {
      setAccordion(accordion.filter(item => item !== id))
    } else {
      setAccordion(accordion => [...accordion, id])
    }
  }
  
  return (
    <div className={`accordion`}>
      <div className='accordion__container container'>
        
        <div className="accordion__content">
          {data.label && (
            <h5 className='accordion__label'>{data.label}</h5>
          )}
          
          {data.header && (
            <h2 className='accordion__header'>{data.header}</h2>
          )}
          
          {data.copy && (
            <p className='accordion__copy'>{data.copy}</p>
          )}
        </div>
        
          
          {data.items.length > 0 && (
            <div className='accordion__row-container'>
            {data.items.map((item, i)=> (
              <div className={`accordion__row ${accordion.includes(item.id) ? 'open' : null}`} key={`row-${item.id}`} id={`${item.id}`}>
                {item.header && (
                  <h5 onClick={()=>accordionAction(item.id)} className='accordion__row-header'>{item.header}</h5>
                )}
                
                {item.copy && (
                  <p className={`accordion__row-copy ${accordion.includes(item.id) ? 'open' : null}`}>{item.copy}</p>
                )}
              </div>
            ))}
            </div>
          )}
      </div>
    </div>
  )
}
export default Accordion

